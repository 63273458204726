/* tslint:disable */
/* eslint-disable */
/**
 * Pyrra
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.3.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObjectiveStatusAvailability
 */
export interface ObjectiveStatusAvailability {
    /**
     * 
     * @type {number}
     * @memberof ObjectiveStatusAvailability
     */
    percentage: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectiveStatusAvailability
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectiveStatusAvailability
     */
    errors: number;
}

export function ObjectiveStatusAvailabilityFromJSON(json: any): ObjectiveStatusAvailability {
    return ObjectiveStatusAvailabilityFromJSONTyped(json, false);
}

export function ObjectiveStatusAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectiveStatusAvailability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'percentage': json['percentage'],
        'total': json['total'],
        'errors': json['errors'],
    };
}

export function ObjectiveStatusAvailabilityToJSON(value?: ObjectiveStatusAvailability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'percentage': value.percentage,
        'total': value.total,
        'errors': value.errors,
    };
}

